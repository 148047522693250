import React from 'react';
import { useImmerReducer } from 'use-immer';
import {
  loginUser,
  getDataWhenAdminLogin,
  getDataWhenAgentLogin,
  getDataWhenClientLoad,
} from 'context/actions';
import { Context } from 'context/providers';
import { useLoad, useGeolocation } from 'hooks';
import {
  createFormData,
  getLocalStorage,
  getUserFromLocalStorage,
  isEmptyObject,
  post,
  removeFromLocalStorage,
  removeSession,
  setHeaders,
  setLocalStorage,
  splitString,
} from 'helpers';
import {
  ADMIN,
  AGENT,
  CLIENT,
  CURRENT_USER_TYPE,
  STORAGE_NAME,
  SUPER_ADMIN,
  TOKEN_EXPIRATION,
  USER_TYPE_ADMIN,
  USER_TYPE_AGENT,
  USER_TYPE_CLIENT,
  USER_TYPE_SUPER_ADMIN,
} from 'constants';
import { USER_TYPES } from 'constants';
const API_ERROR = 'API_ERROR';
const SUCCESS = 'SUCCESS';
const LOADING = 'LOADING';
const CLEAR_MESSAGES = 'CLEAR_MESSAGES';

const initialState = {
  error: '',
  success: '',
  loading: false,
};

function authReducer(draft, action) {
  switch (action.type) {
    case API_ERROR: {
      draft.error = action.payload;
      draft.loading = false;
      draft.success = '';
      return;
    }
    case SUCCESS: {
      draft.error = '';
      draft.loading = false;
      draft.success = action.payload;
      return;
    }
    case LOADING: {
      draft.error = '';
      draft.loading = true;
      draft.success = '';
      return;
    }
    case CLEAR_MESSAGES: {
      draft.error = '';
      draft.loading = false;
      draft.success = '';
      return;
    }
  }
}

export const useAuth = () => {
  const [
    {},
    { saveAllDataOnReload, saveDataOnAgentLoad, saveDataOnClientLoad },
  ] = React.useContext(Context);

  const [{ error, loading, success }, dispatch] = useImmerReducer(
    authReducer,
    initialState
  );

  const {
    loadingLocation,
    location: geolocation,
    validateLocationPermission,
  } = useGeolocation();

  const roles = {
    [USER_TYPE_AGENT]: AGENT,
    [USER_TYPE_CLIENT]: CLIENT,
    [USER_TYPE_ADMIN]: ADMIN,
    [USER_TYPE_SUPER_ADMIN]: SUPER_ADMIN,
  };

  const [socialLoginResponse, setSocialLoginResponse] = React.useState(null);
  const [userType, setUserType] = React.useState(
    getLocalStorage(CURRENT_USER_TYPE)
  );
  const role = roles[getLocalStorage(CURRENT_USER_TYPE)];

  window.addEventListener(CURRENT_USER_TYPE, () => {
    setUserType(getLocalStorage(CURRENT_USER_TYPE));
  });

  const { data, loading: waiting, loaded, loadError, fetchData } = useLoad();
  const {
    data: register,
    loading: registerLoading,
    loaded: registerDone,
    loadError: registerError,
    fetchData: fetchRegister,
  } = useLoad();

  React.useEffect(() => {
    if (loaded && register?.data?.auth) handleResponse(register);
  }, [register, registerLoading, registerDone, registerError]);

  React.useEffect(() => {
    if (loaded) handleResponse(data);
  }, [data, waiting, loaded, loadError]);

  React.useEffect(() => {
    if (!loadingLocation && !isEmptyObject(geolocation))
      loginWithSocialNetwork({
        ...socialLoginResponse,
        geolocation,
      });
  }, [loadingLocation, geolocation]);

  const handleResponse = data => {
    saveDataToContext(role, [
      ...data,
      { status: 200, data: { ...getUserFromLocalStorage(), token: null } },
    ]);
    dispatch({ type: SUCCESS, payload: 'Logged in...' });
  };

  const registerUser = async ({ user, location }) => {
    try {
      const { IPv4, city, country_name, latitude, longitude, state } =
        location.data;
      const params = {
        IPv4,
        city,
        country_name,
        latitude,
        longitude,
        state,
        ...user,
      };
      fetchRegister(() =>
        post({ url: '/register', data: createFormData(params) })
      );
    } catch (error) {
      console.error('error on register: ', error);
    }
  };

  const requestLogin = async user => {
    dispatch({ type: LOADING });
    const { error, data } = await loginUser(user);
    if (error) dispatch({ type: API_ERROR, payload: data });
    else fetchDataByRole(role, data.token);
  };

  function fetchDataByRole(role, token = null) {
    if (token) setHeaders(token);
    const fetch = {
      [AGENT]: () => fetchData(() => getDataWhenAgentLogin()),
      [CLIENT]: () => fetchData(() => getDataWhenClientLoad()),
      [ADMIN]: () => fetchData(() => getDataWhenAdminLogin(USER_TYPES[role])),
      [SUPER_ADMIN]: () =>
        fetchData(() => getDataWhenAdminLogin(USER_TYPES[role])),
    };
    fetch[role]();
  }

  function saveDataToContext(role, data) {
    const save = {
      [AGENT]: () => saveDataOnAgentLoad(data),
      [CLIENT]: () => saveDataOnClientLoad(data),
      [ADMIN]: () => saveAllDataOnReload(data),
      [SUPER_ADMIN]: () => fetchData(() => saveAllDataOnReload(data)),
    };
    save[role]();
  }

  const logout = () => removeSession();

  const clearMesages = () =>
    dispatch({
      type: CLEAR_MESSAGES,
    });

  function SwitchUserType({ title, role }) {
    return (
      <p>
        Not {role === AGENT ? 'an' : 'a'} {role}?{' '}
        <a href="#" onClick={switchUserType}>
          {title}
        </a>
      </p>
    );
  }

  function switchUserType() {
    removeFromLocalStorage(CURRENT_USER_TYPE);
    location.reload();
  }

  const handleSocialLogin = (response, idNetwork) => {
    if (response.accessToken && !response.error) {
      dispatch({ type: LOADING });
      validateLocationPermission();
      setSocialLoginResponse({ ...response, idNetwork });
    } else
      dispatch({
        type: API_ERROR,
        payload:
          response.details ??
          response.error?.message ??
          response.error ??
          'There was an error retrieving your information.',
      });
  };

  function buildUserObjtWithSocialMedia(response) {
    const { idNetwork } = response;
    const statusAndType = {
      AGENT: { user_type: USER_TYPE_AGENT, status: 1 },
      CLIENT: { user_type: USER_TYPE_CLIENT, status: 2 },
    };
    let props = {
      status: statusAndType[role].status,
      user_type: statusAndType[role].user_type,
    };
    if (idNetwork === 'Google') {
      props = {
        email: response.profileObj?.email,
        last_name: response.profileObj?.familyName,
        name: response.profileObj?.givenName,
        service: idNetwork,
        social_id: response.googleId,
        ...props,
      };
    } else {
      props = {
        email: response.email,
        last_name: splitString(response?.name, ' ')[1],
        name: splitString(response?.name, ' ')[0],
        service: idNetwork,
        social_id: response.id,
        ...props,
      };
    }
    return props;
  }

  async function loginWithSocialNetwork(response) {
    try {
      const obj = buildUserObjtWithSocialMedia(response);
      if (!obj.email)
        return dispatch({
          type: API_ERROR,
          payload:
            'We couldn\'t retrieve your email address, please verify your account settings or you can try with other login/register option',
        });
      const res = await post({ url: '/emailSocialExist', data: obj });
      if (res.data.auth !== 0) {
        if (response.geolocation.status === 'granted') {
          const { IPv4, city, country_name, latitude, longitude, state } =
            response.geolocation.data;
          const loginSocial = await post({
            url: '/loginSocial',
            data: {
              IPv4,
              city,
              country_name,
              latitude,
              longitude,
              state,
              ...obj,
            },
          });
          const { auth, token, msg } = loginSocial.data;
          if (auth) {
            setLocalStorage(STORAGE_NAME, loginSocial.data);
            setLocalStorage(TOKEN_EXPIRATION, Date.now() + 2 * 60 * 60 * 1000);
            fetchDataByRole(role, token);
            setSocialLoginResponse(null);
          } else
            dispatch({
              type: API_ERROR,
              payload: msg ?? 'There was an error',
            });
        } else
          dispatch({
            type: API_ERROR,
            payload:
              'Can we access your location? You\'ll be able to apply for a campaign afterwards ',
          });
      }
    } catch (err) {
      console.error('error on social network login: ', err);
    }
  }

  return {
    clearMesages,
    data,
    error,
    fetchDataByRole,
    loaded,
    loadError,
    loading,
    loadingLocation,
    loginUser: requestLogin,
    logout,
    role,
    success,
    switchUserType,
    SwitchUserType,
    user: getUserFromLocalStorage(),
    userType,
    waiting,
    login: {
      loginUser: requestLogin,
      data,
      loading: waiting,
      loaded,
      loadError,
      handleSocialLogin,
    },
    register: {
      registerUser,
      data: register,
      loading: registerLoading,
      loaded: registerDone,
      loadError: registerError,
    },
  };
};
