import React from 'react';
import { get } from 'helpers';

export const useGeolocation = () => {
  const [location, setLocation] = React.useState({});
  const [loadingLocation, setLoadingLocation] = React.useState(false);

  const getIPAddress = async () => await get('https://geolocation-db.com/json/');

  const validateLocationPermission = async () => {
    setLoadingLocation(true);
    const { data: ip } = await getIPAddress();
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: 'geolocation' })
        .then(function (result) {
          if (result.state === 'granted') {
            //If granted then you can directly call your function here
            navigator.geolocation.getCurrentPosition(data =>
              successLocation({ data, status: result.state, ip })
            );
          } else if (result.state === 'prompt') {
            navigator.geolocation.getCurrentPosition(
              data => successLocation({ data, status: result.state, ip }),
              error => locationErrors({ error, status: result.state, ip }),
              {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0,
              }
            );
          } else if (result.state === 'denied') {
            //If denied; show instructions to enable location
            locationErrors({
              error: { message: 'User denied Geolocation' },

              status: result.sate,
              ip,
            });
          }
        });
    } else
      alert(
        'Looks like we\'re having troubles with your browser location, please enable it to continue.'
      );
  };

  async function successLocation({ data, status, ip }) {
    const { latitude, longitude, accuracy } = data.coords;
    setLocation({ data: { ...ip, latitude, longitude, accuracy }, status });
    setLoadingLocation(false);
  }

  function locationErrors({ error, status, ip }) {
    setLocation({ error, status, data: ip });
    setLoadingLocation(false);
  }

  return {
    loadingLocation,
    location,
    validateLocationPermission,
  };
};
